@layer reset, thirdparty, custom, utilities, components, designsystem;

@import "./fonts/diplomis/dazzed/dazzed.css";
@import "./custom.css";
@import "../../node_modules/@tine/designsystem-ui-react/dist/style.css" layer(designsystem);
@import "../../node_modules/@tine/designsystem-tokens/dist/css/diplom-is/variables.css" layer(designsystem);

@layer reset {
   @tailwind base;
}

@tailwind components;
@tailwind utilities;

@layer designsystem {
   .confirmation-banner {
      background-image: url("../img/themes/diplomis/order_confirmation.jpg?width=1792");
      background-image: image-set(
         url("../img/themes/diplomis/order_confirmation.jpg?width=1792&as=webp") 1.01x,
         url("../img/themes/diplomis/order_confirmation.jpg?width=1792") 1.01x
      );
   }
}

@layer utilities {
   :root {
      --default-font-family: "Dazzed", Arial, Helvetica, sans-serif;
      --color-ink-favorite: var(--color-strawberry-800);

      --color-promotion: var(--color-strawberry-800);
      --color-favorite: var(--color-strawberry-800);
      --color-body-bg: var(--color-background-default);
      --color-christmas: var(--color-strawberry-800);
      --color-info-box-bg: var(--color-chocolate-50);
      --color-ink-muted: var(--color-chocolate-500);

      --notification-marker-surface: var(--color-strawberry-800);
      --notification-marker-ink: var(--color-ink-brand-inverted);

      --color-header-footer-bg: var(--color-surface-default-inverted);
      --color-header-footer-text: var(--color-ink-brand-default);
   }
}

@font-face {
   font-family: "suomi";
   src: url("./fonts/Suomi-Hand.otf");
}
